import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import css from "@emotion/css";
import { FaArrowLeft } from "react-icons/fa";
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <div className="blog-post-container">
        <div className="blog-post">
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-bottom: 1.4rem;
            `}
          >
            <Link
              to="/blog/"
              css={css`
                display: flex;
                align-items: center;
                color: black;
              `}
            >
              <FaArrowLeft
                size={35}
                css={css`
                  margin-right: 16px;
                  color: rgba(0, 0, 0, 0.8);
                `}
              />
            </Link>
            <h1
              css={css`
                margin: 0;
              `}
            >
              {frontmatter.title}
            </h1>
          </div>
          <h2>{frontmatter.date}</h2>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
